import React from "react";

export default function Icon({ className, color, name, width, height }) {
  return (
    <svg
      className={className}
      width={width ? width : "20"}
      height={height ? height : "20"}
      fill={color ? color : "black"}
    >
      <use xlinkHref={"#" + name} />
    </svg>
  );
}
