import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../ui/Icon";
import { Table } from "../../components/Table/Table";

export default function Percentage() {
  return (
    <div className="row">
      <div className="col">
        <h3 className="mt-3">Pourcentages</h3>
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Pourcentages
              </li>
            </ol>
          </nav>
          <Link
            className="btn btn-app-default "
            to="/settings/percentages/create"
          >
            <Icon name="add" color="white" /> Nouveau
          </Link>
        </div>

        <Table
          columns={[
            { label: "Valeur inferieur", field: "lower" },
            { label: "Valeur superieur", field: "upper" },
            { label: "Montant", field: "amount" },
            {
              label: "Devise",
              field: "currency",
              render: (e) => `${e.name} (${e.short})`,
            },
            { label: "date de creation", field: "createdAt", isDate: true },
          ]}
          api_url="/api/percentages"
        />
      </div>
    </div>
  );
}
