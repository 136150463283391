import React from "react";
import Select from "react-select";
import Field from "../../../../ui/Input/Field";

export default function Transfers({ currency, amount, frais }) {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 class="card-title">Information du transfert</h5>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label className="form-label">Dévise</label>
          <Select value={currency} isDisabled />
        </div>

        <Field type="number" className="mb-3" value={amount} readOnly>
          Montant a recevoir
        </Field>

        <Field type="number" className="mb-3" value={frais} readOnly>
          Frais de transfert
        </Field>

        <Field type="number" className="mb-3" value={frais + amount} readOnly>
          Total
        </Field>
      </div>
    </div>
  );
}
