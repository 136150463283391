import React, { useState } from "react";
import Field from "../../../ui/Input/Field";
import Select from "react-select";

export default function Send({ errors, clients, loading }) {
  const [firstName, setFirstName] = useState({});
  const [client, setClient] = useState({});
  const preload_client_filter = clients.filter((c) => c.id === client?.value);
  const preload_client =
    preload_client_filter.length > 0 ? preload_client_filter[0] : {};

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 class="card-title">Informations sur l'emetteur</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Prenom
              </label>
              <Select
                isLoading={loading}
                onChange={setFirstName}
                options={clients.map((e) => ({
                  label: e.firstName,
                  value: e.firstName,
                }))}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Nom
              </label>
              <Select
                isLoading={loading}
                onChange={setClient}
                options={clients
                  .filter((e) => e.firstName === firstName?.value)
                  .map((e) => ({
                    label: e.lastName,
                    value: e.id,
                  }))}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              className="mb-3"
              name="sender.firstname"
              error={errors["firstname"]}
              defaultValue={preload_client.firstName}
              required
            >
              Prenom
            </Field>
          </div>
          <div className="col-6">
            <Field
              className="mb-3"
              name="sender.lastname"
              error={errors["lastname"]}
              defaultValue={preload_client.lastName}
              required
            >
              Nom
            </Field>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              className="mb-3"
              name="sender.phone"
              error={errors["phone"]}
              defaultValue={preload_client.phone}
              required
            >
              Numero de telephone
            </Field>
          </div>
          <div className="col-6">
            <Field
              className="mb-3"
              name="sender.email"
              defaultValue={preload_client.email}
              error={errors["email"]}
            >
              E-mail
            </Field>
          </div>
        </div>
        <Field
          className="mb-3"
          name="sender.number_street"
          error={errors["number_street"]}
          defaultValue={preload_client.numberStreet}
          required
        >
          Numero/Rue
        </Field>
      </div>
    </div>
  );
}
