import React from "react";
import Select from "react-select";
import Field from "../../../../ui/Input/Field";

export default function Transfers({
  currencies,
  setRate,
  setCurrency,
  loading,
  currency,
  transfer_currency,
}) {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 class="card-title">Information du Retrait</h5>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label className="form-label">Dévise</label>
          <Select
            isLoading={loading}
            value={currency}
            onChange={setCurrency}
            options={currencies}
          />
        </div>

        {transfer_currency.value !== currency.value && (
          <Field
            type="number"
            step="any"
            className="mb-3"
            onChange={(e) => setRate(parseFloat(e.target.value))}
          >
            Taux de converion
          </Field>
        )}
      </div>
    </div>
  );
}
