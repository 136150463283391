import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../ui/Icon";
import { Table } from "../../components/Table/Table";

export default function Client() {
  return (
    <div className="row">
      <div className="col">
        <h3 className="mt-3">Clients</h3>
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Clients
              </li>
            </ol>
          </nav>
          <Link className="btn btn-app-default " to="/clients/create">
            <Icon name="add" color="white" /> Nouveau
          </Link>
        </div>

        <Table
          columns={[
            { label: "Prenom", field: "firstName" },
            { label: "Nom", field: "lastName" },
            { label: "Telephone", field: "phone" },
            {
              label: "Ville",
              field: "city",
              render: (item) => item.name,
            },
            { label: "date de creation", field: "createdAt", isDate: true },
          ]}
          api_url="/api/clients"
        />
      </div>
    </div>
  );
}
