import React from "react";
import Select from "react-select";

export default function Field({
  name,
  children,
  error,
  options = [],
  required = false,
  isLoading = true,
  className,
  value,
  ...props
}) {
  return (
    <div className="mb-3">
      {children && (
        <>
          <label htmlFor={name} className="form-label">
            {children}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        </>
      )}
      <Select
        isLoading={isLoading}
        name={name}
        options={options}
        value={value}
        className={error ? "is-invalid" : ""}
        {...props}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}
