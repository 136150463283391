import React from "react";
// interface IField {
//   name: string;
//   children?: ReactNode;
//   type?: string;
//   error?: string;
//   required?: boolean;
//   className?: string;
//   defaultValue?: string;
// }

export default function Field({
  name,
  children,
  error,
  type = "text",
  required = false,
  className,
  defaultValue,
  ...props
}) {
  return (
    <div className={`form-group ${className}`}>
      {children && (
        <>
          <label htmlFor={name} className="form-label">
            {children}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        </>
      )}
      <input
        type={type}
        name={name}
        id={name}
        defaultValue={defaultValue}
        required={required}
        className={`form-control${error ? " is-invalid" : ""}`}
        {...props}
      />

      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}
