import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "../../ui/Button";
import { ApiGeneric, ApiErrors } from "../../api";
import Field from "../../ui/Input/Field";
import Select from "../../ui/Input/Select";

const api = new ApiGeneric();

export default function Edit() {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  async function read() {
    try {
      setLoading(true);
      const response = await api.onSend(`/api/percentages/${id}`);
      const response_currencies = await api.onSend("/api/currencies");
      setData(response);
      setCurrency({
        label: response?.currency.name,
        value: response?.currency.id,
      });
      setCurrencies(
        response_currencies?.items?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    } catch (e) {}

    setLoading(false);
  }
  async function update(e) {
    e.preventDefault();
    setLoader(true);
    let form = new FormData(e.target);
    const data = Object.fromEntries(form);
    for (let key in data) {
      if (key !== "currency") {
        data[key] = parseFloat(data[key]);
      }
    }
    try {
      await api.onSend(`/api/percentages/${id}`, {
        method: "PUT",
        data,
      });
      alert("Succes");
      navigate(-1);
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }
    setLoader(false);
  }

  useEffect(() => {
    read();
  }, []);

  return (
    <div className="row">
      <div className="col">
        <h3 className="mt-3">Pourcentages</h3>
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <Link className="text-muted" to="/settings/percentages">
                  Pourcentages
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Mise a jour pourcentage
              </li>
            </ol>
          </nav>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={update}>
                  <h4 className="information-trait">Information</h4>
                  <Field
                    type="number"
                    step="any"
                    className="mb-3"
                    name="lower"
                    error={errors["lower"]}
                    defaultValue={data?.lower}
                    required
                  >
                    Valeur inferieur
                  </Field>

                  <Field
                    type="number"
                    step="any"
                    className="mb-3"
                    name="upper"
                    defaultValue={data?.upper}
                    error={errors["upper"]}
                    required
                  >
                    Valuer superieur
                  </Field>

                  <Field
                    type="number"
                    step="any"
                    className="mb-3"
                    name="amount"
                    defaultValue={data?.amount}
                    error={errors["amount"]}
                    required
                  >
                    Montant
                  </Field>

                  <Select
                    isLoading={loading}
                    name="currency"
                    options={currencies}
                    className="mb-4"
                    required
                    value={currency}
                    onChange={setCurrency}
                    error={errors["currency"]}
                  >
                    Dévise
                  </Select>

                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Enregistrer
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
