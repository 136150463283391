import { useState, useEffect } from "react";
import { ApiErrors, ApiGeneric } from "../../api";
import { NavLink, useLocation } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import "./style.css";

const API = new ApiGeneric();
export const Table = ({
  api_url,
  columns = [],
  can_delete = true,
  can_update = true,
  can_view = false,
  search,
}) => {
  const [data, setData] = useState({
    items: [],
    currentPageNumber: 1,
    numItemsPerPage: 10,
    totalCount: 0,
    pagesInRange: [],
    previous: 0,
    next: 0,
  });
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  async function load(type) {
    setLoading(true);
    try {
      let url = api_url;
      if (search) {
        url += "?" + search;
      }
      let response = await API.getPaginate(url, type);
      setData(response);
    } catch (e) {
      if (e instanceof ApiErrors) {
      } else {
      }
    }
    setLoading(false);
  }

  async function onDelete(e, id) {
    e.preventDefault();
    const answer = window.confirm(
      "Souhaitez-vous vraiment supprimé l'élément?"
    );
    if (answer) {
      try {
        await API.onSend(`${api_url}/${id}`, { method: "DELETE" });
        load("delete");
      } catch (e) {
        if (e instanceof ApiErrors) {
        } else {
        }
      }
    }
  }

  useEffect(() => {
    load("init");
  }, [search]);

  const onLimitChange = async (e) => {
    API.rowsPerPage = e.target.value;
    API.page = 1;
    await load("row_per_page");
  };

  const onPageChange = async (e, page) => {
    e.preventDefault();
    if (page) {
      API.page = page;
      await load("page");
    }
  };

  return (
    <>
      {loading && (
        <div className="row justify-content-center">
          <div className="col-lg-1">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      <table className="table mt-4">
        <thead>
          <tr>
            {columns.map((column, key) => {
              return <th key={key}>{column.label}</th>;
            })}
            {(can_update || can_view || can_delete) && (
              <th>
                <tr>Actions</tr>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((item, key) => {
            return (
              <tr key={key}>
                {columns.map((i) => {
                  if (i.render) {
                    return <td>{i.render(item[i.field])}</td>;
                  }

                  if (i.self) {
                    return <td>{i.self(item)}</td>;
                  }

                  if (i.field in item) {
                    if (i.isDate) {
                      return (
                        <td>
                          {moment(item[i.field]).utc().format("DD/MM/YYYY")}
                        </td>
                      );
                    }
                    if (i.isTime) {
                      return (
                        <td>
                          {moment(item[i.field]).utc().format("HH:mm:ss")}
                        </td>
                      );
                    } else {
                      return <td>{item[i.field]}</td>;
                    }
                  } else {
                    return <td />;
                  }
                })}
                {(can_update || can_view || can_delete) && (
                  <td className="action">
                    {can_update && (
                      <NavLink to={`${location.pathname}/edit/${item.id}`}>
                        <span className="material-symbols-sharp">edit</span>
                      </NavLink>
                    )}

                    {can_view && (
                      <NavLink to={`${location.pathname}/view/${item.id}`}>
                        <span className="material-symbols-sharp">
                          visibility
                        </span>
                      </NavLink>
                    )}

                    {can_delete && (
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => onDelete(e, item.id)}
                      >
                        <span className="material-symbols-sharp">delete</span>
                      </span>
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="main-pagination">
        <select onChange={onLimitChange}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={200}>200</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
          <option value={5000}>5000</option>
        </select>
        <div className="pagination">
          <a
            href="#!"
            onClick={(e) => {
              onPageChange(e, data.previous);
            }}
          >
            &laquo;
          </a>
          <a href="#">{API.page}</a>
          <a
            href="#!"
            onClick={(e) => {
              onPageChange(e, data.next);
            }}
          >
            &raquo;
          </a>
        </div>
      </div>
    </>
  );
};

const column = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    isDate: PropTypes.bool,
    isTime: PropTypes.bool,
    render: PropTypes.func,
    self: PropTypes.func,
  })
);

Table.propTypes = {
  api_url: PropTypes.string.isRequired,
  columns: column,
  can_delete: PropTypes.bool,
  can_update: PropTypes.bool,
  can_view: PropTypes.bool,
  search: PropTypes.string,
};
