import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "../../ui/Button";
import { ApiGeneric, ApiErrors } from "../../api";
import Field from "../../ui/Input/Field";

const api = new ApiGeneric();

export default function Edit() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  async function read() {
    try {
      const response = await api.onSend(`/api/cities/${id}`);
      setData(response);
    } catch (e) {}
  }
  async function update(e) {
    e.preventDefault();
    setLoader(true);
    let form = new FormData(e.target);
    const data = Object.fromEntries(form);
    try {
      await api.onSend(`/api/cities/${id}`, {
        method: "PUT",
        data,
      });
      alert("Succes");
      navigate(-1);
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }
    setLoader(false);
  }

  useEffect(() => {
    read();
  }, []);

  return (
    <div className="row">
      <div className="col">
        <h3 className="mt-3">Villes</h3>
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <Link className="text-muted" to="/settings/cities">
                  Villes
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Mise a jour ville
              </li>
            </ol>
          </nav>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={update}>
                  <h4 className="information-trait">Information</h4>
                  <Field
                    className="mb-3"
                    name="name"
                    error={errors["name"]}
                    defaultValue={data?.name}
                    required
                  >
                    Nom
                  </Field>

                  <Field
                    className="mb-3"
                    name="description"
                    error={errors["description"]}
                    defaultValue={data?.description}
                  >
                    description
                  </Field>

                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Save
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
