import React from "react";
import Select from "react-select";
import Field from "../../../ui/Input/Field";

export default function Transfers({
  currencies,
  currency,
  errors,
  percentages,
  amount,
  setAmount,
  setCurrency,
  loading,
}) {
  const frais = percentages
    .filter(
      (e) =>
        e.lower < amount &&
        amount <= e.upper &&
        e?.currency?.id === currency?.id
    )
    .reduce((a, c) => a + c.amount, 0);

  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 class="card-title">Information du transfert</h5>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label className="form-label">
            Dévise <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            isLoading={loading}
            required
            onChange={setCurrency}
            options={currencies}
          />
        </div>

        <Field
          type="number"
          required
          className="mb-3"
          onChange={(e) => setAmount(parseInt(e.target.value))}
        >
          Montant a envoyer
        </Field>

        <Field type="number" className="mb-3" value={amount} disabled>
          Montant a recevoir
        </Field>

        <Field type="number" className="mb-3" value={frais} disabled>
          Frais de transfert
        </Field>

        <Field type="number" className="mb-3" value={frais} disabled>
          Frais de transfert
        </Field>

        <Field type="number" className="mb-3" value={frais + amount} disabled>
          Total
        </Field>
      </div>
    </div>
  );
}
