import React, { useState } from "react";
import Button from "../../ui/Button";
import { ApiGeneric } from "../../api";
import logo from "../../assets/logo.jpg";

const api = new ApiGeneric();
export default function Login() {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);

  async function auth(e) {
    sessionStorage.removeItem("userToken");
    e.preventDefault();
    setLoader(true);
    const form = new FormData(e.target);
    const data = Object.fromEntries(form);

    try {
      await api.onSend("/login", {
        method: "POST",
        data,
      });

      setStep(2);
    } catch (a) {
      setError("Addresse e-mail ou mot de passe incorrect!");
    }

    setLoader(false);
  }

  async function conformation(e) {
    e.preventDefault();
    setLoader(true);
    const form = new FormData(e.target);
    const data = Object.fromEntries(form);

    try {
      const response = await api.onSend("/api/auth/verification", {
        method: "POST",
        data,
      });

      sessionStorage.setItem("userToken", response.token);
      sessionStorage.setItem("userRole", JSON.stringify(response.roles));
      window.location.replace("/home");
    } catch (a) {
      setError(a.errors);
    }

    setLoader(false);
  }

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-4">
          <div className="mb-5">
            <h1 className="fw-bold text-center color-app-default">
              <img src={logo} width="300" alt="PICCO" />
            </h1>
          </div>

          <div className="card">
            <div className="card-body">
              {step === 1 ? (
                <form onSubmit={auth}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Adresse e-mail
                    </label>
                    <input
                      id="username"
                      type="text"
                      className="form-control"
                      name="username"
                      required
                    />
                  </div>
                  <div className="mb-3 form-group">
                    <label htmlFor="password" className="form-label">
                      Mot de passe
                    </label>
                    <div className="input-group">
                      <input
                        id="password"
                        type={show ? "text" : "password"}
                        required
                        className="form-control"
                        name="password"
                      />
                      <div className="input-group-prepend">
                        <span
                          onClick={(e) => setShow((s) => !s)}
                          className="input-group-text"
                        >
                          <i className={`bi bi-eye${show ? "-slash" : ""}`}></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Connexion
                  </Button>
                </form>
              ) : (
                <form onSubmit={conformation}>
                  <div className="mb-3">
                    <label htmlFor="code" className="form-label">
                      Code d'authentification
                    </label>
                    <input
                      type="text"
                      id="code"
                      className="form-control"
                      name="code"
                      defaultValue=""
                      required
                    />
                  </div>
                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Connexion
                  </Button>
                </form>
              )}
            </div>
          </div>
          {error && (
            <div className="alert alert-danger alert-dismissible fade show mt-3">
              <strong>{error}</strong>
              <button
                type="button"
                className="btn-close"
                onClick={() => setError(false)}
              ></button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
