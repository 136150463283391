import React from "react";

function Button({ onLoad = false, children, ...props }) {
  return (
    <>
      {onLoad ? (
        <button {...props} disabled>
          <span
            className="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          />
          {children}
        </button>
      ) : (
        <button {...props}>{children}</button>
      )}
    </>
  );
}

export default Button;
