import React, { useEffect, useState } from "react";
import Button from "../../../ui/Button";
import { ApiErrors, ApiGeneric } from "../../../api";
import Receiver from "./components/Receiver";
import Sender from "./components/Sender";
import Transfers from "./components/Transfers";
import Retrait from "./components/Retrait";

const api = new ApiGeneric();
export default function Details({ data }) {
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState({});
  const [transfersCurrency, setTransfersCurrency] = useState({});
  const [rate, setRate] = useState(1);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const fetchData = async () => {
    const response_currencies = await api.onSend("/api/currencies");
    setCurrencies(
      response_currencies?.items?.map((e) => {
        const value = {
          label: e.name,
          value: e.short,
          id: e.id,
        };

        if (data?.currency === e.short) {
          setCurrency(value);
          setTransfersCurrency(value);
        }

        return value;
      })
    );
  };

  async function create(e) {
    e.preventDefault();
    setLoader(true);
    const target = e.target;
    const form = new FormData(target);
    const dataIn = Object.fromEntries(form);

    const send_data = { sender: {}, receiver: {} };
    for (let field in dataIn) {
      const [type, field_name] = field.split(".");
      send_data[type][field_name] = dataIn[field];
    }

    send_data["transaction"] = {
      currency: currency.value,
      amount: rate * data?.amount,
      rate,
      costs: rate * data?.costs,
      code_mtcn: data?.codeMtcn,
    };

    try {
      await api.onSend("/api/transactions/receive", {
        method: "POST",
        data: send_data,
      });

      alert("Succes");
      window.location.replace("/receive");
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }

    setLoader(false);
  }

  async function cancel(e) {
    e.preventDefault();
    setLoader(true);

    try {
      await api.onSend("/api/transactions/send/" + data.id, {
        method: "PUT",
        data: { cancel: true },
      });

      alert("Succes");
      window.location.replace("/receive");
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }

    setLoader(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row mt-5">
      <div className="col-lg-8">
        <form onSubmit={create}>
          <Transfers
            currency={transfersCurrency}
            frais={data?.costs}
            amount={data?.amount}
          />
          <Sender sender={data?.sender} errors={errors} />
          <Receiver receiver={data?.receiver} errors={errors} />
          <Retrait
            currency={currency}
            transfer_currency={transfersCurrency}
            setRate={setRate}
            setCurrency={setCurrency}
            currencies={currencies}
          />
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <Button
                    type="submit"
                    onLoad={loader}
                    onClick={cancel}
                    className="btn btn-app-default w-100"
                  >
                    Annuler
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn text-bg-dark w-100"
                  >
                    Enregistrer
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-4">
        <div className="card">
          <div className="card-header">
            <h5 class="card-title">Résumé</h5>
          </div>
          <div className="card-body">
            <h6>
              Montant du transfert: {data?.amount} {currency.value}
            </h6>

            <h6>
              Total pour le beneficiare: {data?.amount} {currency.value}
            </h6>

            <h6>
              Taux de conversion: {rate} {currency.value}
            </h6>

            <hr />
            <h5>
              TOTAL: {rate * data?.amount} {currency.value}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
