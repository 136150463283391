import React from "react";
import logo from "../assets/logo.jpg";
import Icon from "../ui/Icon";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header>
      <div className="px-3 py-2 text-bg-dark">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a
              href="/home"
              onClick={() => window.location.reload()}
              className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
            >
              <img src={logo} width="100" alt="PICCO" />
            </a>

            <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
              <li>
                <Link to="/send" className="nav-link text-white">
                  <Icon
                    className="bi d-block mx-auto mb-1"
                    name={"send"}
                    color="white"
                  />
                  Envoyer
                </Link>
              </li>

              <li>
                <Link to="/receive" className="nav-link text-white">
                  <Icon
                    className="bi d-block mx-auto mb-1"
                    name={"money"}
                    color="white"
                  />
                  Recevoir
                </Link>
              </li>

              <li>
                <Link to="/report" className="nav-link text-white">
                  <Icon
                    className="bi d-block mx-auto mb-1"
                    name={"spreadsheet"}
                    color="white"
                  />
                  Journal des transactions
                </Link>
              </li>

              {localStorage.getItem("userRole") != "CLIENT" && (
                <>
                  <li className="dropdown">
                    <a
                      className="nav-link text-white dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Icon
                        className="bi d-block mx-auto mb-1"
                        name={"option"}
                        color="white"
                      />
                      Options
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/clients">
                          Clients
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/users">
                          Agents
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <span className="text-muted m-3">Réglages</span>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/settings/cities"
                          href="#"
                        >
                          Villes
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/settings/percentages"
                          href="#"
                        >
                          Pourcentages
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
