import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../ui/Button";
import Field from "../../ui/Input/Field";
import Select from "../../ui/Input/Select";
import { ApiGeneric, ApiErrors } from "../../api";

const api = new ApiGeneric();

export default function Create() {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState([]);
  const [errors, setErrors] = useState({});

  const fetchData = async () => {
    setLoading(true);
    const response_currencies = await api.onSend("/api/currencies");
    setCurrencies(
      response_currencies?.items?.map((e) => ({
        label: e.name,
        value: e.id,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function create(e) {
    e.preventDefault();
    setLoader(true);
    const target = e.target;
    const form = new FormData(target);
    const data = Object.fromEntries(form);
    for (let key in data) {
      if (key !== "currency") {
        data[key] = parseFloat(data[key]);
      }
    }
    try {
      await api.onSend("/api/percentages", {
        method: "POST",
        data,
      });
      target.reset();
      alert("Succes");
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }

    setLoader(false);
  }

  return (
    <div className="row">
      <div className="col">
        <h3 className="mt-3">Pourcentages</h3>
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <Link className="text-muted" to="/settings/percentages">
                  Pourcentages
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Nouveau Pourcentage
              </li>
            </ol>
          </nav>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={create}>
                  <h4 className="information-trait">Information</h4>
                  <Field
                    type="number"
                    step="any"
                    className="mb-3"
                    name="lower"
                    error={errors["lower"]}
                    required
                  >
                    Valeur inferieur
                  </Field>

                  <Field
                    type="number"
                    step="any"
                    className="mb-3"
                    name="upper"
                    error={errors["upper"]}
                    required
                  >
                    Valuer superieur
                  </Field>

                  <Field
                    type="number"
                    step="any"
                    className="mb-3"
                    name="amount"
                    error={errors["amount"]}
                    required
                  >
                    Montant
                  </Field>

                  <Select
                    isLoading={loading}
                    name="currency"
                    options={currencies}
                    className="mb-4"
                    required
                    error={errors["currency"]}
                  >
                    Dévise
                  </Select>

                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Enregistrer
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
