import React, { useState } from "react";
import Button from "../../ui/Button";
import Select from "react-select";
import Icon from "../../ui/Icon";
import { Table } from "../../components/Table/Table";

export default function Report() {
  const [search, setSearch] = useState("?type=receive");
  const [type, setType] = useState({ label: "Retrait", value: "receive" });
  const onFilter = (e) => {
    e.preventDefault();
    const target = e.target;
    const form = new FormData(target);
    const data = Object.fromEntries(form);
    let local_search = "";
    for (let key in data) {
      if (data[key].length > 0) {
        local_search += `${key}=${data[key]}&`;
      }
    }
    setSearch(local_search);
  };
  return (
    <div className="row mt-5">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <form onSubmit={onFilter}>
              <div className="row">
                <div className="col">
                  <input
                    type="date"
                    placeholder="Date début"
                    className="form-control"
                    name="debut"
                  />
                </div>
                <div className="col">
                  <input
                    type="date"
                    placeholder="Date fin"
                    className="form-control"
                    name="fin"
                  />
                </div>

                <div className="col">
                  <Select
                    name="type"
                    value={type}
                    onChange={setType}
                    options={[
                      { label: "Retrait", value: "receive" },
                      { label: "Envoi", value: "send" },
                      { label: "Annuler", value: "cancel" },
                      { label: "Impayer", value: "not-receive" },
                    ]}
                    placeholder="Type"
                  />
                </div>
                <div className="col">
                  <Button type="submit" className="btn btn-app-default w-100">
                    Recherche
                  </Button>
                </div>
                <div className="col">
                  <Button type="submit" className="btn btn-app-default w-100">
                    {" "}
                    <Icon name="print" color="white" /> Imprimer
                  </Button>
                </div>
                <div className="col">
                  <Button type="submit" className="btn btn-app-default w-100">
                    <Icon name="excel" color="white" /> Exporter
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Table
        search={search}
        can_update={false}
        can_delete={false}
        columns={[
          { label: "Date d'envoie", field: "createdAt", isDate: true },
          { label: "Heure d'envoie", field: "createdAt", isTime: true },
          { label: "MTCN", field: "codeMtcn" },
          {
            label: "Expéditeur",
            field: "sender",
            render: (e) => `${e.firstName} ${e.lastName}`,
          },
          {
            label: "Bénéficiaire",
            field: "receiver",
            render: (e) => `${e.firstName} ${e.lastName}`,
          },
          {
            label: "Montant envoyé",
            self: (e) => `${e.amount} ${e.currency}`,
          },
          {
            label: "Montant Total",
            self: (e) => `${e.amount + e.costs} ${e.currency}`,
          },
          {
            label: "Montant A destination",
            self: (e) => `${e.amount} ${e.currency}`,
          },
        ]}
        api_url="/api/transactions"
      />
    </div>
  );
}
