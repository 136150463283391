import React, { useState } from "react";
import Field from "../../../../ui/Input/Field";

export default function Send({ errors, sender }) {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 class="card-title">Informations sur l'emetteur</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <Field
              className="mb-3"
              name="sender.firstname"
              error={errors["firstname"]}
              value={sender?.firstName}
              readOnly
            >
              Prenom
            </Field>
          </div>
          <div className="col-6">
            <Field
              className="mb-3"
              name="sender.lastname"
              error={errors["lastname"]}
              value={sender?.lastName}
              readOnly
            >
              Nom
            </Field>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Field
              className="mb-3"
              name="sender.phone"
              error={errors["phone"]}
              value={sender?.phone}
              readOnly
            >
              Numero de telephone
            </Field>
          </div>
          <div className="col-6">
            <Field
              className="mb-3"
              name="sender.email"
              value={sender?.email}
              error={errors["email"]}
              readOnly
            >
              E-mail
            </Field>
          </div>
        </div>
        <Field
          className="mb-3"
          name="sender.number_street"
          error={errors["number_street"]}
          value={sender?.numberStreet}
          readOnly
        >
          Numero/Rue
        </Field>
      </div>
    </div>
  );
}
