import React, { useEffect, useState } from "react";
import Button from "../../ui/Button";
import { ApiErrors, ApiGeneric } from "../../api";
import Receiver from "./components/Receiver";
import Sender from "./components/Sender";
import Transfers from "./components/Transfers";

const api = new ApiGeneric();
export default function Send() {
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState({});
  const [amount, setAmount] = useState(0);
  const [clients, setClients] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  const frais = percentages
    .filter(
      (e) =>
        e.lower < amount &&
        amount <= e.upper &&
        e?.currency?.id === currency?.id
    )
    .reduce((a, c) => a + c.amount, 0);

  const fetchData = async () => {
    setLoading(true);
    const response_currencies = await api.onSend("/api/currencies");
    const response_clients = await api.onSend("/api/clients");
    const response_percentages = await api.onSend("/api/percentages");
    setCurrencies(
      response_currencies?.items?.map((e) => ({
        label: e.name,
        value: e.short,
        id: e.id,
      }))
    );
    setClients(response_clients?.items);
    setPercentages(response_percentages?.items);
    setLoading(false);
  };

  async function create(e) {
    e.preventDefault();
    setLoader(true);
    const target = e.target;
    const form = new FormData(target);
    const data = Object.fromEntries(form);
    const send_data = { sender: {}, receiver: {} };
    for (let field in data) {
      const [type, field_name] = field.split(".");
      send_data[type][field_name] = data[field];
    }
    send_data["transaction"] = {
      currency: currency.value,
      amount,
      costs: frais,
    };

    try {
      await api.onSend("/api/transactions/send", {
        method: "POST",
        data: send_data,
      });
      // target.reset();
      alert("Succes");
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }

    setLoader(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row mt-5">
      <div className="col-lg-8">
        <form onSubmit={create}>
          <Transfers
            currencies={currencies}
            currency={currency}
            errors={errors}
            percentages={percentages}
            amount={amount}
            setAmount={setAmount}
            setCurrency={setCurrency}
            loading={loading}
          />
          <Sender loading={loading} clients={clients} errors={errors} />
          <Receiver errors={errors} />
          <div className="card mb-3">
            <div className="card-body">
              <Button
                type="submit"
                onLoad={loader}
                className="btn btn-app-default w-100"
              >
                Enregistrer
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-lg-4">
        <div className="card">
          <div className="card-header">
            <h5 class="card-title">Résumé</h5>
          </div>
          <div className="card-body">
            <h6>
              Montant du transfert: {amount} {currency.value}
            </h6>

            <h6>
              Frais de transfert: {frais} {currency.value}
            </h6>

            <h6>
              Total pour le beneficiare: {amount} {currency.value}
            </h6>

            <hr />
            <h5>
              TOTAL: {amount + frais} {currency.value}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
