import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Send from "./pages/Send";
import Receive from "./pages/Receive";
import City from "./pages/City";
import CreateCity from "./pages/City/Create";
import EditCity from "./pages/City/Edit";
import Percentage from "./pages/Percentage";
import CreatePercentage from "./pages/Percentage/Create";
import EditPercentage from "./pages/Percentage/Edit";
import Client from "./pages/Client";
import CreateClient from "./pages/Client/Create";
import EditClient from "./pages/Client/Edit";
import User from "./pages/User";
import CreateUser from "./pages/User/Create";
import EditUser from "./pages/User/Edit";
import Report from "./pages/Report";
import Header from "./components/Header";

function App() {
  return (
    <div>
      {window.location.pathname !== "/" && <Header />}
      <div className="container">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/send" element={<Send />} />
          {/* users */}
          <Route path="/users">
            <Route index element={<User />} />
            <Route path="create" element={<CreateUser />} />
            <Route path="edit/:id" element={<EditUser />} />
          </Route>
          {/* clients */}
          <Route path="/clients">
            <Route index element={<Client />} />
            <Route path="create" element={<CreateClient />} />
            <Route path="edit/:id" element={<EditClient />} />
          </Route>
          {/* settings */}
          <Route path="/settings">
            <Route path="cities">
              <Route index element={<City />} />
              <Route path="create" element={<CreateCity />} />
              <Route path="edit/:id" element={<EditCity />} />
            </Route>
            <Route path="percentages">
              <Route index element={<Percentage />} />
              <Route path="create" element={<CreatePercentage />} />
              <Route path="edit/:id" element={<EditPercentage />} />
            </Route>
          </Route>
          <Route path="/report" element={<Report />} />
          <Route path="/receive" element={<Receive />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
