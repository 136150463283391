import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ApiGeneric, ApiErrors } from "../../api";
import Field from "../../ui/Input/Field";
import Select from "../../ui/Input/Select";
import Button from "../../ui/Button";

const api = new ApiGeneric();
export default function Edit() {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState({});
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    const response = await api.onSend(`/api/users/${id}`);
    setData(response);
    setCity({
      label: response?.city.name,
      value: response?.city.id,
    });
    const response_currencies = await api.onSend("/api/cities");
    setCities(
      response_currencies?.items?.map((e) => ({
        label: e.name,
        value: e.id,
      }))
    );
    setLoading(false);
  };

  async function update(e) {
    e.preventDefault();
    setLoader(true);
    let form = new FormData(e.target);
    const data = Object.fromEntries(form);
    try {
      await api.onSend(`/api/users/${id}`, {
        method: "PUT",
        data,
      });
      alert("Succes");
      navigate(-1);
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }
    setLoader(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row">
      <div className="col">
        <h3 className="mt-3">Agents</h3>
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <Link className="text-muted" to="/users">
                  Agents
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Mise a jour
              </li>
            </ol>
          </nav>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={update}>
                  <h4 className="information-trait">Information</h4>
                  <Field
                    error={errors["fullname"]}
                    className="mb-3"
                    name="fullname"
                    defaultValue={data.fullname}
                    required
                  >
                    Nom compet
                  </Field>
                  <Field
                    error={errors["email"]}
                    defaultValue={data.email}
                    name="email"
                    className="mb-3"
                    required
                  >
                    E-mail
                  </Field>

                  <Select
                    isLoading={loading}
                    name="city"
                    options={cities}
                    onChange={setCity}
                    className="mb-4"
                    required
                    value={city}
                    error={errors["city"]}
                  >
                    Ville
                  </Select>

                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Enregistrer
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
