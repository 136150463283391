import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../ui/Button";
import Field from "../../ui/Input/Field";
import { ApiGeneric, ApiErrors } from "../../api";

const api = new ApiGeneric();

export default function Create() {
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});

  async function create(e) {
    e.preventDefault();
    setLoader(true);
    const target = e.target;
    const form = new FormData(target);
    const data = Object.fromEntries(form);
    try {
      await api.onSend("/api/cities", {
        method: "POST",
        data,
      });
      setLoader(false);
      target.reset();
      alert("Succes");
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }

    setLoader(false);
  }

  return (
    <div className="row">
      <div className="col">
        <h3 className="mt-3">Villes</h3>
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <Link className="text-muted" to="/settings/cities">
                  Villes
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Nouvelle ville
              </li>
            </ol>
          </nav>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={create}>
                  <h4 className="information-trait">Information</h4>
                  <Field
                    className="mb-3"
                    name="name"
                    error={errors["name"]}
                    required
                  >
                    Nom
                  </Field>

                  <Field
                    className="mb-3"
                    name="description"
                    error={errors["description"]}
                  >
                    description
                  </Field>

                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Enregistrer
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
