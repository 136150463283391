import axios from "axios";
import { config } from "../constants";

export class ApiErrors {
  constructor(errors) {
    this.errors = errors;
  }

  get errorsPerField() {
    return this.errors.reduce((acc, error) => {
      return { ...acc, [error.field]: error.message };
    }, {});
  }
}

class APISingleTon {
  static instance;
  _page = 1;
  _rowsPerPage = 10;

  static get getInstance() {
    return this.instance ? this.instance : (this.instance = new this());
  }

  get page() {
    return this._page;
  }

  set page(value) {
    this._page = value;
  }

  get rowsPerPage() {
    return this._rowsPerPage;
  }

  set rowsPerPage(value) {
    this._rowsPerPage = value;
  }
}

export class ApiGeneric {
  headers = {
    Accept: "application/json",
  };

  async onSend(endpoint, options = {}) {
    const token = sessionStorage.getItem("userToken", false);
    if (token) {
      this.headers.Authorization = token && "Bearer " + token;
    }

    options = {
      headers: this.headers,
      withCredentials: true,
      url: config.server + endpoint,
      ...options,
    };

    if (
      options.data !== null &&
      typeof options.data === "object" &&
      !(options.data instanceof FormData)
    ) {
      options.data = JSON.stringify(options.data);
      options.headers["Content-Type"] = "application/json";
    }

    const response = await axios(options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Object.assign({}, error).response;
      });

    if (response.status === 204) {
      return null;
    }

    if (response.status === 401) {
      const data = response.data;
      if (data?.code === 401 && data?.message !== "Invalid credentials.") {
        window.location.replace(window.location.origin);
        return null;
      }
    }

    if (response.ok || response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      if (response.data) {
        throw new ApiErrors(response.data);
      }
    }
  }

  addToUrl(url) {
    let urlIn = url;
    if (urlIn.includes("?")) {
      urlIn += "&";
    } else {
      urlIn += "?";
    }
    return urlIn;
  }

  async getPaginate(action, type) {
    const singleton = APISingleTon.getInstance;
    let url = action;
    if (type === "row_per_page") {
      url = this.addToUrl(url);
      url += "limit=" + singleton.rowsPerPage + "&page=1";
    } else if (type === "page") {
      url = this.addToUrl(url);
      url += "page=" + singleton.page + "&limit=" + singleton.rowsPerPage;
    } else {
      url = this.addToUrl(url);
      url += "page=" + singleton.page + "&limit=" + singleton.rowsPerPage;
    }

    return await this.onSend(url, {});
  }

  get page() {
    const singleton = APISingleTon.getInstance;
    return singleton.page;
  }

  set page(value) {
    const singleton = APISingleTon.getInstance;
    singleton.page = value;
  }

  get rowsPerPage() {
    const singleton = APISingleTon.getInstance;
    return singleton.rowsPerPage;
  }

  set rowsPerPage(value) {
    const singleton = APISingleTon.getInstance;
    singleton.rowsPerPage = value;
  }
}
