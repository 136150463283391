import React from "react";
import Field from "../../../../ui/Input/Field";

export default function Receiver({ errors, receiver }) {
  return (
    <div className="card mb-3">
      <div className="card-header">
        <h5 class="card-title">Informations sur le beneficieur</h5>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <Field
              className="mb-3"
              name="receiver.firstname"
              error={errors["firstname"]}
              value={receiver?.firstName}
              readOnly
            >
              Prenom
            </Field>
          </div>
          <div className="col-6">
            <Field
              className="mb-3"
              name="receiver.lastname"
              value={receiver?.lastName}
              error={errors["lastname"]}
              readOnly
            >
              Nom
            </Field>
          </div>
        </div>
      </div>
    </div>
  );
}
