import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Icon from "../../ui/Icon";
import { ApiGeneric } from "../../api";

const api = new ApiGeneric();
export default function Home() {
  const [data, setData] = useState({ receive: 0, send: 0, cancel: 0 });

  const fetchStat = async () => {
    try {
      const response = await api.onSend("/api/statistics/dashboard");
      setData(response);
    } catch (e) {}
  };

  useEffect(() => {
    fetchStat();
  }, []);

  return (
    <>
      <div className="row mt-3">
        <div className="col">
          <Card
            value={data.send}
            name="Operations d'envoie"
            icon={<Icon name="send" color="tomato" />}
          />
        </div>
        <div className="col">
          <Card
            value={data.receive}
            name="Operations de retrait"
            icon={<Icon name="money" color="green" />}
          />
        </div>
        <div className="col">
          <Card
            value={data.cancel}
            name="Operations annuler"
            icon={<Icon name="cancel" color="red" />}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col">
          <div className="card">
            <div className="d-flex justify-content-between m-3">
              <h6 className="fw-bold">
                <Icon /> Envoi & Retrait
              </h6>
              <div>
                <select className="form-select">
                  <option value="week">Semaine</option>
                  <option value="month">Mois</option>
                </select>
              </div>
            </div>
            <div
              className="card-body"
              style={{
                height: 440,
                width: "100%",
              }}
            >
              <ResponsiveContainer>
                <LineChart
                  data={[
                    {
                      time: "01/02/2023",
                      send: 2,
                      receive: 3,
                    },
                    {
                      time: "02/02/2023",
                      send: 6,
                      receive: 9,
                    },
                    {
                      time: "03/02/2023",
                      send: 0,
                      receive: 9,
                    },
                    {
                      time: "04/02/2023",
                      send: 12,
                      receive: 46,
                    },
                    {
                      time: "05/02/2023",
                      send: 72,
                      receive: 30,
                    },
                    {
                      time: "06/02/2023",
                      send: 18,
                      receive: 37,
                    },
                  ]}
                  margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                  }}
                >
                  <XAxis dataKey="time" stroke={"black"} />
                  <YAxis stroke={"black"} />
                  <CartesianGrid strokeDasharray="2 2" />
                  <Line type="monotone" dataKey="send" stroke={"tomato"} />

                  <Line type="monotone" dataKey="receive" stroke={"green"} />

                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
