import React, { useState } from "react";
import Button from "../../ui/Button";
import Details from "./Details";
import Field from "../../ui/Input/Field";
import { ApiGeneric } from "../../api";

const api = new ApiGeneric();

export default function Receive() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState("");

  async function codeVerification(e) {
    e.preventDefault();
    setLoader(true);
    const target = e.target;
    const form = new FormData(target);
    const data = Object.fromEntries(form);

    try {
      const response = await api.onSend("/api/transactions/code-verification", {
        method: "POST",
        data,
      });
      setData(response);
      target.reset();
    } catch (e) {
      setErrors(e.errors);
    }

    setLoader(false);
  }

  return !data ? (
    <div className="row mt-5">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <form onSubmit={codeVerification}>
              <div className="row">
                <div className="col">
                  <Field
                    required
                    error={errors}
                    placeholder="Code mtcn *"
                    name="code"
                  />
                </div>
                <div className="col-lg-3">
                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Recherche
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Details data={data} />
  );
}
