import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../ui/Button";
import Field from "../../ui/Input/Field";
import Select from "../../ui/Input/Select";
import { ApiGeneric, ApiErrors } from "../../api";

const api = new ApiGeneric();

export default function Create() {
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});

  async function create(e) {
    e.preventDefault();
    setLoader(true);
    const target = e.target;
    const form = new FormData(target);
    const data = Object.fromEntries(form);
    try {
      await api.onSend("/api/clients", {
        method: "POST",
        data,
      });
      alert("Succes");
      target.reset();
    } catch (e) {
      if (e instanceof ApiErrors) {
        setErrors(e.errorsPerField);
      }
    }

    setLoader(false);
  }

  const fetchData = async () => {
    setLoading(true);
    const response_currencies = await api.onSend("/api/cities");
    setCities(
      response_currencies?.items?.map((e) => ({
        label: e.name,
        value: e.id,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="row">
      <div className="col">
        <h3 className="mt-3">Clients</h3>
        <div className="d-flex justify-content-between">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                <Link className="text-muted" to="/clients">
                  Clients
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Nouveau client
              </li>
            </ol>
          </nav>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-body">
                <form onSubmit={create}>
                  <h4 className="information-trait">Information</h4>
                  <Field
                    error={errors["firstname"]}
                    className="mb-3"
                    name="firstname"
                    required
                  >
                    Prenom
                  </Field>
                  <Field
                    error={errors["lastname"]}
                    className="mb-3"
                    name="lastname"
                    required
                  >
                    Nom
                  </Field>
                  <Field
                    error={errors["phone"]}
                    className="mb-3"
                    name="phone"
                    required
                  >
                    Numero de telephone
                  </Field>
                  <Field error={errors["email"]} name="email" className="mb-3">
                    E-mail
                  </Field>
                  <Field
                    error={errors["number_street"]}
                    name="number_street"
                    className="mb-3"
                  >
                    Adresse
                  </Field>

                  <Select
                    isLoading={loading}
                    name="city"
                    options={cities}
                    className="mb-4"
                    required
                    error={errors["city"]}
                  >
                    Ville
                  </Select>

                  <Button
                    type="submit"
                    onLoad={loader}
                    className="btn btn-app-default w-100"
                  >
                    Enregistrer
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
